<template>
  <nav class="sidebar" :class="sidebarTheme">
    <ul class="nav mt-2 mb-4">
      <li v-if="myOrganizations.length > 1" class="nav-item nav-organization-selector">
        <div class="mb-3">
          <b-form-select v-model="selectedOrganization" :options="organizationsOptions" :disabled="disableSelect"
            size="sm" @change="switchOrganization"></b-form-select>
        </div>
      </li>
      <li v-if="branchOptions.length > 0" class="nav-item nav-organization-selector">
        <div class="mb-3">
          <b-form-select v-model="selectedBranch" :options="branchOptions" :disabled="disableSelect" size="sm"
            @change="switchBranch"></b-form-select>
        </div>
      </li>

      <!-- <li class="nav-item">
          <router-link class="nav-link" :to="{ name: 'DashboardHome' }">
            <i class="menu-icons"></i>
            <span class="menu-title">主介面</span>
          </router-link>
        </li> -->
      <li v-if="checkPermissionAny([
        consts.CHATROOM_LIST_CHAT_VIEW,
        consts.MY_CUSTOMER_LIST,
        consts.MY_REDEEM_CODE_LIST_VIEW,
        consts.MY_DEPT_CUSTOMER_LIST,
        consts.MY_EXPORT,
      ])
        " class="nav-item">
        <a class="nav-link" v-b-toggle="'ui-my-console'">
          <i class="menu-icons"></i>
          <span class="menu-title">我的主控台</span>
          <i class="menu-arrow"></i>
        </a>
        <b-collapse id="ui-my-console">
          <ul class="nav flex-column sub-menu">
            <li v-if="checkPermission([consts.CHATROOM_LIST_CHAT_VIEW])" class="nav-item">
              <router-link class="nav-link" :to="{ name: 'ChatroomIndex' }">聊天室總覽</router-link>
            </li>
            <li v-if="checkPermission([consts.MY_CUSTOMER_LIST])" class="nav-item">
              <router-link class="nav-link" :to="{ name: 'MyConsoleStaffCustomerList' }">我的客戶</router-link>
            </li>
            <li v-if="checkPermission([consts.MY_REDEEM_CODE_LIST_VIEW])" class="nav-item">
              <router-link class="nav-link" :to="{ name: 'MyConsoleRedeemCodeBundleList' }">我的優惠券</router-link>
            </li>
            <li v-if="checkPermission([consts.MY_DEPT_CUSTOMER_LIST])" class="nav-item">
              <router-link class="nav-link" :to="{ name: 'MyConsoleBranchStaffCustomerList' }">部門客戶</router-link>
            </li>
            <li v-if="checkPermission([consts.MY_EXPORT])" class="nav-item">
              <router-link class="nav-link" :to="{ name: 'MyConsoleDownloadFileList' }">資料匯出</router-link>
            </li>
          </ul>
        </b-collapse>
      </li>

      <li v-if="checkPermissionAny([consts.OA_MESSAGE_VIEW, consts.OA_PUSH_VIEW, consts.LINE_MENU_LIST_VIEW])"
        class="nav-item">
        <a class="nav-link" v-b-toggle="'oa'">
          <i class="menu-icons"></i>
          <span class="menu-title">LINE 應用中心</span>
          <i class="menu-arrow"></i>
        </a>
        <b-collapse id="oa">
          <ul class="nav flex-column sub-menu">
            <li v-if="checkPermission([consts.OA_MESSAGE_VIEW])" class="nav-item">
              <router-link class="nav-link" :to="{ name: 'OAMessage' }">LINE 聊天</router-link>
            </li>
            <li v-if="checkPermission([consts.OA_PUSH_VIEW])" class="nav-item">
              <router-link class="nav-link" :to="{ name: 'PushMessage' }">群發訊息</router-link>
            </li>
            <li v-if="checkPermission([consts.OA_PUSH_VIEW])" class="nav-item">
              <router-link class="nav-link" :to="{ name: 'PushMessageTemplate' }">訊息範本</router-link>
            </li>
            <li v-if="checkPermission([consts.LINE_MENU_LIST_VIEW])" class="nav-item">
              <router-link class="nav-link" :to="{ name: 'RichMenu' }">圖文選單</router-link>
            </li>
          </ul>
        </b-collapse>
      </li>

      <li v-if="checkPermissionAny([
        consts.CUSTOMER_LIST_VIEW,
        consts.CUSTOMER_LEVEL_LIST,
        consts.CUSTOMER_LIST_WITH_PROFILE_VIEW,
        consts.AUDIENCE_LIST_VIEW,
        consts.TAG_LIST_VIEW,
      ])
        " class="nav-item">
        <a class="nav-link" v-b-toggle="'customer'">
          <i class="menu-icons"></i>
          <span class="menu-title">會員管理中心</span>
          <i class="menu-arrow"></i>
        </a>
        <b-collapse id="customer">
          <ul class="nav flex-column sub-menu">
            <li v-if="checkPermission([consts.CUSTOMER_LIST_VIEW])" class="nav-item">
              <router-link class="nav-link" :to="{ name: 'CustomerList' }">會員管理</router-link>
            </li>
            <li v-if="checkPermission([consts.CUSTOMER_LEVEL_LIST])" class="nav-item">
              <router-link class="nav-link" :to="{ name: 'CustomerLevelsList' }">會員等級管理</router-link>
            </li>
            <li v-if="checkPermission([consts.AUDIENCE_LIST_VIEW])" class="nav-item">
              <router-link class="nav-link" :to="{ name: 'AudienceList' }">受眾管理</router-link>
            </li>
            <li v-if="checkPermission([consts.TAG_LIST_VIEW])" class="nav-item">
              <router-link class="nav-link" :to="{ name: 'TagList' }">標籤列表</router-link>
            </li>
          </ul>
        </b-collapse>
      </li>

      <li v-if="checkPermissionAny([
        consts.CORE_GROUPBUYING_LIST_VIEW,
        consts.CORE_GROUPBUYING_CREATE,
        consts.CORE_GROUPBUYING_MODIFY,
        consts.CORE_GROUPBUYING_PRODUCT_LIST,
      ])" class="nav-item">
        <a class="nav-link" v-b-toggle="'core_groupbuyings'">
          <i class="menu-icons"></i>
          <span class="menu-title">團購管理</span>
          <i class="menu-arrow"></i>
        </a>
        <b-collapse id="core_groupbuyings">
          <ul class="nav flex-column sub-menu">
            <li v-if="checkPermission([consts.CORE_GROUPBUYING_LIST_VIEW])" class="nav-item">
              <router-link class="nav-link" :to="{ name: 'CoreGroupbuyingsListV2' }">團購列表</router-link>
            </li>
            <li v-if="checkPermissionAny([
              consts.CORE_GROUPBUYING_CREATE,
              consts.CORE_GROUPBUYING_MODIFY,
            ])" class="nav-item">
              <router-link class="nav-link" :to="{ name: 'CoreGroupbuyingsList' }">團購草稿</router-link>
            </li>
            <li v-if="checkPermissionAny([
              consts.CORE_GROUPBUYING_PRODUCT_LIST,
            ])" class="nav-item">
              <router-link class="nav-link" :to="{ name: 'CoreGroupbuyingsProductList' }">團購商品管理</router-link>
            </li>
          </ul>
        </b-collapse>
      </li>

      <li v-if="checkPermissionAny([consts.PARTNER_WORKER_LIST_VIEW, consts.PARTNER_LIST_VIEW, consts.PARTNER_STAFF_LIST_VIEW])" class="nav-item">
        <a class="nav-link" v-b-toggle="'partner'">
          <i class="menu-icons"></i>
          <span class="menu-title">{{ displayText('partner.partner_area', '合作夥伴專區') }}</span>
          <i class="menu-arrow"></i>
        </a>
        <b-collapse id="partner">
          <ul class="nav flex-column sub-menu">
            <li v-if="checkPermission([consts.PARTNER_LIST_VIEW])" class="nav-item">
              <router-link class="nav-link" :to="{ name: 'PartnerList' }">{{ displayText('partner.partner_management', '合作夥伴管理') }}</router-link>
            </li>
            <li v-if="checkPermission([consts.PARTNER_WORKER_LIST_VIEW])" class="nav-item">
              <router-link class="nav-link" :to="{ name: 'PartnerWorkerList' }">{{ displayText('partner.partner_worker_management', '夥伴員工管理') }}</router-link>
            </li>
            <li v-if="checkPermission([consts.PARTNER_WORKER_VERIFY])" class="nav-item">
              <router-link class="nav-link" :to="{ name: 'PartnerWorkerListVerifying', query:{ status: 'pending', page_type: 'verify' }}">{{ displayText('partner.partner_worker_verify_page', '夥伴員工審核') }}</router-link>
            </li>
            <li v-if="checkPermission([consts.PARTNER_STAFF_LIST_VIEW])" class="nav-item">
              <router-link class="nav-link" :to="{ name: 'PartnerStaffEntry' }">{{ displayText('partner.partner_staff_list', '夥伴帳號列表') }}</router-link>
            </li>
          </ul>
        </b-collapse>
      </li>

      <li v-if="checkPermissionAny([
        consts.GIFT_LIST_VIEW,
        consts.REDEEM_CODE_LIST_VIEW,
        consts.POINT_LIST_VIEW,
        consts.POINT_LOGS_LIST_VIEW,
        consts.REDEEM_LIST_VIEW,
        consts.BADGE_LIST_VIEW
      ])" class="nav-item">
        <a class="nav-link" v-b-toggle="'ui-regular-customer'">
          <i class="menu-icons"></i>
          <span class="menu-title">獎勵應用中心</span>
          <i class="menu-arrow"></i>
        </a>
        <b-collapse id="ui-regular-customer">
          <ul class="nav flex-column sub-menu">
            <li v-if="checkPermission([consts.POINT_LOGS_LIST_VIEW])" class="nav-item">
              <router-link class="nav-link" :to="{ name: 'PointLogList' }">點數異動紀錄</router-link>
            </li>
            <li v-if="checkPermission([consts.POINT_LIST_VIEW])" class="nav-item">
              <router-link class="nav-link" :to="{ name: 'PointList' }">入點紀錄</router-link>
            </li>
            <li v-if="checkPermission([consts.REDEEM_LIST_VIEW])" class="nav-item">
              <router-link class="nav-link" :to="{ name: 'RedeemLogList' }">贈品兌換紀錄</router-link>
            </li>
            <li v-if="checkPermission([consts.REDEEM_CODE_LIST_VIEW])" class="nav-item">
              <router-link class="nav-link" :to="{ name: 'RedeemCodeBundleList' }">優惠券管理</router-link>
            </li>
            <li v-if="checkPermission([consts.GIFT_LIST_VIEW])" class="nav-item">
              <router-link class="nav-link" :to="{ name: 'GiftList' }">贈品管理</router-link>
            </li>
            <li v-if="checkPermission([consts.BADGE_LIST_VIEW])" class="nav-item">
              <router-link class="nav-link" :to="{ name: 'BadgeList' }">徽章管理</router-link>
            </li>
          </ul>
        </b-collapse>
      </li>

      <!-- <li v-if="$permissions.hasAny([
        $permissions.consts.BRANCH_LIST_VIEW,
        $permissions.consts.BRANCH_MODIFY,
      ])
        " class="nav-item">
        <a class="nav-link" v-b-toggle="'branches'">
          <i class="menu-icons"></i>
          <span class="menu-title">分店管理</span>
          <i class="menu-arrow"></i>
        </a>
        <b-collapse id="branches">
          <ul class="nav flex-column sub-menu">
            <li v-if="checkPermission([consts.BRANCH_LIST_VIEW])" class="nav-item">
              <router-link class="nav-link" :to="{ name: 'Branches' }">分店列表</router-link>
            </li>
            <li v-if="checkPermission([consts.BRANCH_MODIFY])" class="nav-item">
              <router-link class="nav-link" :to="{ name: 'BranchesCreate' }">新增分店</router-link>
            </li>
          </ul>
        </b-collapse>
      </li> -->




      <li v-if="checkPermissionAny([
        consts.MEMBER_EVENT_LIST_VIEW,
        consts.ACTION_EVENT_LIST_VIEW,
        consts.SHOPPING_EVENT_LIST_VIEW,
        consts.CUSTOMIZE_EVENT_LIST_VIEW,
        consts.MKT_CONFIG_LIST_VIEW])" class="nav-item">
        <a class="nav-link" v-b-toggle="'auto-module'">
          <i class="menu-icons"></i>
          <span class="menu-title">自動化模組</span>
          <i class="menu-arrow"></i>
        </a>
        <b-collapse id="auto-module">
          <ul class="nav flex-column sub-menu">
            <li v-if="checkPermission([consts.MEMBER_EVENT_LIST_VIEW])" class="nav-item">
              <router-link class="nav-link" to="#">會員事件設定</router-link>
            </li>
            <li v-if="checkPermission([consts.ACTION_EVENT_LIST_VIEW])" class="nav-item">
              <router-link class="nav-link" to="#">活動事件設定</router-link>
            </li>
            <li v-if="checkPermission([consts.SHOPPING_EVENT_LIST_VIEW])" class="nav-item">
              <router-link class="nav-link" to="#">購物事件設定</router-link>
            </li>
            <li v-if="checkPermission([consts.CUSTOMIZE_EVENT_LIST_VIEW])" class="nav-item">
              <router-link class="nav-link" to="#">自定義事件</router-link>
            </li>
            <li v-if="checkPermission([consts.MKT_CONFIG_LIST_VIEW])" class="nav-item">
              <router-link class="nav-link" :to="{ name: 'AutoMarketingList' }">行銷自動化</router-link>
            </li>
          </ul>
        </b-collapse>
      </li>


      <li v-if="checkPermissionAny([
        consts.SHORT_URL_VIEW
      ])" class="nav-item">
        <a class="nav-link" v-b-toggle="'ui-marketing-tool'">
          <i class="menu-icons"></i>
          <span class="menu-title">行銷應用中心</span>
          <i class="menu-arrow"></i>
        </a>
        <b-collapse id="ui-marketing-tool">
          <ul class="nav flex-column sub-menu">
            <li v-if="checkPermission([consts.SHORT_URL_VIEW])" class="nav-item">
              <router-link class="nav-link" :to="{ name: 'ShortUrlList' }">短網址管理</router-link>
            </li>

          </ul>
        </b-collapse>
      </li>
      <li v-if="checkPermissionAny([
        consts.EVENT_LIST_VIEW,
        consts.POST_LIST_VIEW,
        consts.NOTIFICATION_LIST,
        consts.FILE_LIST_VIEW,
      ])" class="nav-item">
        <a class="nav-link" v-b-toggle="'ui-event'">
          <i class="menu-icons"></i>
          <span class="menu-title">內容管理中心</span>
          <i class="menu-arrow"></i>
        </a>
        <b-collapse id="ui-event">
          <ul class="nav flex-column sub-menu">
            <li v-if="checkPermission([consts.EVENT_LIST_VIEW])" class="nav-item">
              <router-link class="nav-link" :to="{ name: 'EventList' }">活動列表</router-link>
            </li>
            <li v-if="checkPermission([consts.POST_LIST_VIEW])" class="nav-item">
              <router-link class="nav-link" :to="{ name: 'PostList' }">文章列表</router-link>
            </li>

            <li v-if="checkPermission([consts.NOTIFICATION_LIST])" class="nav-item">
              <router-link class="nav-link" :to="{ name: 'NotificationList' }">通知列表</router-link>
            </li>
            <li v-if="checkPermission([consts.FILE_LIST_VIEW])" class="nav-item">
              <router-link class="nav-link" :to="{ name: 'UploadFileList' }">檔案列表</router-link>
            </li>
          </ul>
        </b-collapse>
      </li>
      <li v-if="checkPermissionAny([
        consts.ORDER_LIST_VIEW,
        consts.PRODUCT_LIST_VIEW,
        consts.INVOICE_LIST_VIEW,
      ])" class="nav-item">
        <a class="nav-link" v-b-toggle="'orders'">
          <i class="menu-icons"></i>
          <span class="menu-title">銷售應用中心</span>
          <i class="menu-arrow"></i>
        </a>
        <b-collapse id="orders">
          <ul class="nav flex-column sub-menu">
            <li v-if="checkPermission([consts.ORDER_LIST_VIEW])" class="nav-item">
              <router-link class="nav-link" :to="{ name: 'OrderList' }">訂單列表</router-link>
            </li>
            <li v-if="checkPermission([consts.PRODUCT_LIST_VIEW])" class="nav-item">
              <router-link class="nav-link" :to="{ name: 'ProductList' }">商品列表</router-link>
            </li>

          </ul>
        </b-collapse>
      </li>


      <li v-if="checkPermissionAny([

      ])" class="nav-item">
        <a class="nav-link" v-b-toggle="'settings'">
          <i class="menu-icons"></i>
          <span class="menu-title">系統設定</span>
          <i class="menu-arrow"></i>
        </a>
        <b-collapse id="settings">
          <ul class="nav flex-column sub-menu">


          </ul>
        </b-collapse>
      </li>

      <li v-if="checkPermissionAny([
        consts.INVOICE_LIST_VIEW,
        consts.INVOICE_LOG_LIST_VIEW
      ])" class="nav-item">
        <a class="nav-link" v-b-toggle="'invoice'">
          <i class="menu-icons"></i>
          <span class="menu-title">發票模組</span>
          <i class="menu-arrow"></i>
        </a>
        <b-collapse id="invoice">
          <ul class="nav flex-column sub-menu">
            <li v-if="checkPermission([consts.INVOICE_LIST_VIEW])" class="nav-item">
              <router-link class="nav-link" :to="{ name: 'InvoiceList' }">發票列表</router-link>
            </li>
            <li v-if="checkPermission([consts.INVOICE_LOG_LIST_VIEW])" class="nav-item">
              <router-link class="nav-link" :to="{ name: 'InvoiceLogList' }">發票審核列表</router-link>
            </li>
          </ul>
        </b-collapse>
      </li>


      <li v-if="checkPermissionAny([
        consts.STAFF_LIST_VIEW,
        consts.BRANCH_LIST_VIEW,
        consts.POINT_RULE_VIEW,
        consts.DAILY_ACT_LIST_VIEW,
        consts.MKT_RULE_LIST_VIEW,
      ])" class="nav-item">
        <a class="nav-link" v-b-toggle="'tag-management'">
          <i class="menu-icons"></i>
          <span class="menu-title">控制中心</span>
          <i class="menu-arrow"></i>
        </a>
        <b-collapse id="tag-management">
          <ul class="nav flex-column sub-menu">
            <li v-if="checkPermission([consts.STAFF_LIST_VIEW])" class="nav-item">
              <router-link class="nav-link" :to="{ name: 'StaffList' }">帳號列表</router-link>
            </li>
            <li v-if="checkPermission([consts.BRANCH_LIST_VIEW])" class="nav-item">
              <router-link class="nav-link" :to="{ name: 'Branches' }">分店列表</router-link>
            </li>

            <li v-if="checkPermission([consts.POINT_RULE_VIEW])" class="nav-item">
              <router-link class="nav-link" :to="{ name: 'PointRuleList' }">入點比例列表</router-link>
            </li>
            <li v-if="checkPermission([consts.DAILY_ACT_LIST_VIEW])" class="nav-item">
              <router-link class="nav-link" :to="{ name: 'DailyActivityList' }">每日活動列表</router-link>
            </li>
            <li v-if="checkPermission([consts.MKT_RULE_LIST_VIEW])" class="nav-item">
              <router-link class="nav-link" :to="{ name: 'MarketingRuleList' }">行銷規則列表</router-link>
            </li>

          </ul>
        </b-collapse>
      </li>



      <li v-if="checkPermissionAny([
        consts.LINE_SETTING_MODIFY,
        consts.LIFF_CARD_SETTING_MODIFY,
        consts.LIFF_UI_SETTING_MODIFY
      ])" class="nav-item">
        <a class="nav-link" v-b-toggle="'params-settings'">
          <i class="menu-icons"></i>
          <span class="menu-title">系統參數設定</span>
          <i class="menu-arrow"></i>
        </a>
        <b-collapse id="params-settings">
          <ul class="nav flex-column sub-menu">
            <li v-if="checkPermission([consts.LINE_SETTING_MODIFY])" class="nav-item">
              <router-link class="nav-link" to="#">LINE 參數設定</router-link>
            </li>
            <li v-if="checkPermission([consts.LIFF_CARD_SETTING_MODIFY])" class="nav-item">
              <router-link class="nav-link" :to="{ name: 'LiffCardSetting' }">會員卡畫面</router-link>
            </li>
            <li v-if="checkPermission([consts.LIFF_UI_SETTING_MODIFY])" class="nav-item">
              <router-link class="nav-link" :to="{ name: 'LiffUISetting' }">會員介面設定</router-link>
            </li>
          </ul>
        </b-collapse>
      </li>


      <li v-if="showOrgSetting" class="nav-item">
        <a class="nav-link" v-b-toggle="'org-settings'">
          <i class="menu-icons"></i>
          <span class="menu-title">組織資料管理</span>
          <i class="menu-arrow"></i>
        </a>
        <b-collapse id="org-settings">
          <ul class="nav flex-column sub-menu">
            <li class="nav-item" v-if="showOrgSetting">
              <router-link class="nav-link" :to="{ name: 'OrganizationHome' }">組織管理</router-link>
            </li>
            <li class="nav-item" v-if="checkPermission([consts.MODULE_LIST_VIEW])">
              <router-link class="nav-link" :to="{
                name: 'ModuleList',
                params: { orgId: organization.id },
              }">
                模組管理
              </router-link>
            </li>
            <li class="nav-item" v-if="checkPermission([consts.ORGANIZATION_VARIABLE])">
              <router-link class="nav-link" :to="{ name: 'OrganizationVariableList' }">
                參數管理
              </router-link>
            </li>
            <li class="nav-item" v-if="checkPermission([consts.ROLE_LIST_VIEW])">
              <router-link class="nav-link" :to="{ name: 'RoleList' }">
                角色權限管理
              </router-link>
            </li>
            <li class="nav-item" v-if="checkPermission([consts.SUITE_SETTING])">
              <router-link class="nav-link" :to="{ name: 'SuiteList' }">
                方案套件設定
              </router-link>
            </li>
            <li class="nav-item" v-if="checkPermission([consts.KEYWORD_LIST_VIEW])">
              <router-link class="nav-link" :to="{ name: 'KeywordList' }">
                關鍵字管理
              </router-link>
            </li>
            <li class="nav-item" v-if="checkPermission([consts.LOGS_LIST_VIEW])">
              <router-link class="nav-link" :to="{ name: 'ServerLogList' }">
                系統紀錄
              </router-link>
            </li>
            <li class="nav-item" v-if="checkPermission([consts.SQL_RUNNER_VIEW])">
              <router-link class="nav-link" :to="{ name: 'SqlRunnerList' }">
                SQL 排程管理
              </router-link>
            </li>
            <li class="nav-item" v-if="checkPermission([consts.TOKEN_LIST_VIEW])">
              <router-link class="nav-link" :to="{ name: 'TokenList' }">
                API TOKEN 管理
              </router-link>
            </li>
          </ul>
        </b-collapse>
      </li>
      <li v-if="checkPermission([consts.ORGANIZATION_LIST_VIEW])" class="nav-item">
        <a class="nav-link" v-b-toggle="'system-settings'">
          <i class="menu-icons"></i>
          <span class="menu-title">全系統設定</span>
          <i class="menu-arrow"></i>
        </a>
        <b-collapse id="system-settings">
          <ul class="nav flex-column sub-menu">
            <li class="nav-item">
              <router-link class="nav-link" :to="{ name: 'OrganizationList' }">組織管理</router-link>
            </li>
          </ul>
        </b-collapse>
      </li>
      <li v-if="checkPermissionAny([
        consts.GROUPBUYING_LIST_VIEW,
        consts.SELECTION_GROUPBUYING_CREATE,
        consts.STORE_GROUPBUYING_CREATE,
        consts.GROUPBUYING_BLACKLIST,
      ])
        " class="nav-item">
        <a class="nav-link" v-b-toggle="'groupbuyings'">
          <i class="menu-icons"></i>
          <span class="menu-title">分店團購管理</span>
          <i class="menu-arrow"></i>
        </a>
        <b-collapse id="groupbuyings">
          <ul class="nav flex-column sub-menu">
            <li v-if="checkPermission([consts.GROUPBUYING_LIST_VIEW])" class="nav-item">
              <router-link class="nav-link" :to="{ name: 'GroupbuyingsList', query: { tab: 'going' } }">團購列表</router-link>
            </li>
            <li v-if="checkPermission([consts.STORE_GROUPBUYING_CREATE])" class="nav-item">
              <router-link class="nav-link" :to="{ name: 'StoreGroupbuyingScanProduct' }">門市庫存品開團</router-link>
            </li>
            <li v-if="checkPermission([consts.SELECTION_GROUPBUYING_CREATE])" class="nav-item">
              <router-link class="nav-link" :to="{ name: 'GroupbuyingsCreate' }">總公司選品開團</router-link>
            </li>
            <!-- <li
              v-if="checkPermission([consts.STORE_GROUPBUYING_DRAFT])"
              class="nav-item"
            >
              <router-link
                class="nav-link"
                :to="{ name: 'StoreGroupbuyingDraft' }"
                >門市庫存品-草稿</router-link
              >
            </li> -->
            <li v-if="checkPermission([consts.GROUPBUYING_BLACKLIST])" class="nav-item">
              <router-link class="nav-link" :to="{
                name: 'BlacklistList',
                params: { branchID: selectedBranch },
              }">逾期未取名單</router-link>
            </li>
          </ul>
        </b-collapse>
      </li>
      <li v-if="checkPermissionAny([
        consts.POYA_AUTOAWARD_VIEW,
        consts.MAIN_GROUPBUYING_LIST_VIEW,
        consts.MAIN_STORE_GROUPBUYING_CREATE,
        consts.MAIN_GROUPBUYING_CREATE,
        consts.EVENT_PRODUCT_LIST_VIEW,
        consts.THEME_GROUPBUYING_LIST_VIEW,
        consts.GROUPBUYING_SKU_QTY_UPDATE,
        consts.POYA_GROUPBUYINGORDER_SEARCH,
      ])
        " class="nav-item">
        <a class="nav-link" v-b-toggle="'groupbuyingsMain'">
          <i class="menu-icons"></i>
          <span class="menu-title">總公司團購管理</span>
          <i class="menu-arrow"></i>
        </a>
        <b-collapse id="groupbuyingsMain">
          <ul class="nav flex-column sub-menu">
            <li class="nav-item" v-if="checkPermissionAny([consts.POYA_AUTOAWARD_VIEW])">
              <router-link class="nav-link" :to="{ name: 'AutoawardList' }">自動派獎設定</router-link>
            </li>
            <li v-if="checkPermission([consts.MAIN_GROUPBUYING_LIST_VIEW])" class="nav-item">
              <router-link class="nav-link"
                :to="{ name: 'MainGroupbuyingsList', query: { tab: 'going' } }">總公司團購列表</router-link>
            </li>
            <li v-if="checkPermission([consts.MAIN_STORE_GROUPBUYING_CREATE])" class="nav-item">
              <router-link class="nav-link" :to="{ name: 'MainGroupbuyingScanProduct' }">門市庫存品開團</router-link>
            </li>
            <li v-if="checkPermission([consts.MAIN_GROUPBUYING_CREATE])" class="nav-item">
              <router-link class="nav-link" :to="{ name: 'MainGroupbuyingsCreate' }">總公司選品開團</router-link>
            </li>
            <li v-if="checkPermission([consts.GROUPBUYING_SKU_QTY_UPDATE])" class="nav-item">
              <router-link class="nav-link" :to="{ name: 'MainGroupbuyingsBatch' }">下單數量設定</router-link>
            </li>
            <li class="nav-item" v-if="checkPermission([consts.EVENT_PRODUCT_LIST_VIEW])">
              <router-link class="nav-link" :to="{ name: 'ProductWhiteList' }">商品白名單設定</router-link>
            </li>
            <li v-if="checkPermission([consts.THEME_GROUPBUYING_LIST_VIEW])" class="nav-item">
              <router-link class="nav-link" :to="{ name: 'ThemeGroupbuyingList' }">主題團購列表</router-link>
            </li>
            <li class="nav-item" v-if="checkPermission([consts.POYA_GROUPBUYINGORDER_SEARCH])">
              <router-link class="nav-link" :to="{ name: 'PoyaGroupbuyingOrderList' }">顧客訂單查詢</router-link>
            </li>
          </ul>
        </b-collapse>
      </li>
      <li v-if="checkPermission([consts.GROUP_LIST_VIEW_1]) ||
        checkPermission([consts.GROUP_LIST_VIEW_2]) ||
        checkPermission([consts.GROUP_LIST_VIEW_3])
        " class="nav-item">
        <a class="nav-link" v-b-toggle="'groups'">
          <i class="menu-icons"></i>
          <span class="menu-title">群組管理</span>
          <i class="menu-arrow"></i>
        </a>
        <b-collapse id="groups">
          <ul class="nav flex-column sub-menu">
            <li v-if="checkPermission([consts.GROUP_LIST_VIEW_1]) ||
              checkPermission([consts.GROUP_LIST_VIEW_2]) ||
              checkPermission([consts.GROUP_LIST_VIEW_3])
              " class="nav-item">
              <router-link class="nav-link" :to="{ name: 'GroupList' }">群組列表</router-link>
            </li>
          </ul>
        </b-collapse>
      </li>
      <li v-if="checkPermission([consts.GUARD_GROUP_LIST_VIEW]) ||
        checkPermission([consts.GUARD_BLACK_GLOBAL_MODIFY]) ||
        checkPermission([consts.GUARD_GROUP_SETTING_LIST_VIEW]) ||
        checkPermission([consts.GUARD_MEMBER_LIST_VIEW]) ||
        checkPermission([consts.GUARD_REPORT_LIST_VIEW]) ||
        checkPermission([consts.GROUP_MESSAGE_CREATE])
        " class="nav-item">
        <a class="nav-link" v-b-toggle="'group-protect'">
          <i class="menu-icons"></i>
          <span class="menu-title">群組保護</span>
          <i class="menu-arrow"></i>
        </a>
        <b-collapse id="group-protect">
          <ul class="nav flex-column sub-menu">
            <li v-if="checkPermission([consts.GUARD_MEMBER_LIST_VIEW])" class="nav-item">
              <router-link class="nav-link" :to="{ name: 'GroupProtectMembers' }">成員名單設定</router-link>
            </li>
            <li v-if="checkPermission([consts.GUARD_BLACK_GLOBAL_MODIFY])" class="nav-item">
              <router-link class="nav-link" :to="{ name: 'GroupProtectBlackMembers' }">廣告黑名單(全店)管理
              </router-link>
            </li>
            <li v-if="checkPermission([consts.GUARD_GROUP_SETTING_LIST_VIEW])" class="nav-item">
              <router-link class="nav-link" :to="{ name: 'GroupProtectSetting' }">群組保護設定</router-link>
            </li>
            <li v-if="checkPermission([consts.GUARD_GROUP_LIST_VIEW])" class="nav-item">
              <router-link class="nav-link" :to="{ name: 'GuardGroups' }">衛兵群組列表</router-link>
            </li>
            <li v-if="checkPermission([consts.GUARD_REPORT_LIST_VIEW])" class="nav-item">
              <router-link class="nav-link" :to="{ name: 'GroupProtectGuards' }">衛兵狀態列表</router-link>
            </li>
            <li v-if="checkPermission([consts.GROUP_MESSAGE_CREATE])" class="nav-item">
              <router-link class="nav-link" :to="{ name: 'GroupMessageCreate' }">群組廣播</router-link>
            </li>
          </ul>
        </b-collapse>
      </li>
    </ul>
  </nav>
</template>

<script lang="js">
import { mapState, mapActions, mapGetters } from 'vuex'
import PermissionChecker from '@/utils/PermissionChecker'
import { displayText } from '@/utils/dashboardUtils'
import * as consts from '@/consts'

export default {
  name: 'app-sidebar',
  props: ['themeConfig'],
  data() {
    return {
      disableSelect: false,
      selectedBranch: null,
      selectedOrganization: null,
      consts: consts
    }
  },
  mounted() {
    this.init()

    // 設定 Dashboard 的 Title
    if (this.themeConfig.og_title !== null || this.themeConfig.og_title !== undefined) {
      document.title = this.themeConfig.og_title;
    } else {
      document.title = "";
    }

    // 設定 Dashboard 的 favicon
    if (this.themeConfig.og_favicon_url !== null || this.themeConfig.og_favicon_url !== undefined) {
      var link = document.querySelector("link[rel~='icon']");
      if (!link) {
        link = document.createElement('link');
        link.rel = 'icon';
        document.getElementsByTagName('head')[0].appendChild(link);
      }
      link.href = this.themeConfig.og_favicon_url;
    }
  },
  computed: {
    ...mapState('general', {
      myBranches: state => state.myBranches,
      currentBranch: state => state.currentBranch,
      myOrganizations: state => state.myOrganizations,
      organization: state => state.organization,
      sidebarTheme: (state) => state.sidebarTheme,
    }),
    ...mapGetters('general', ['role', 'branchOptions']),
    organizationsOptions() {
      return this.myOrganizations.map(organization => {
        return {
          value: organization.id,
          text: organization.name
        }
      })
    },
    showOrgSetting() {
      return this.checkPermission([consts.ORGANIZATION_VARIABLE]) ||
        this.checkPermission([consts.ROLE_LIST_VIEW]) ||
        this.checkPermission([consts.MODULE_LIST_VIEW]) ||
        this.checkPermission([consts.KEYWORD_LIST_VIEW]) ||
        this.checkPermission([consts.SQL_RUNNER_VIEW]) ||
        this.checkPermission([consts.MERCHANT_LIST_VIEW]) ||
        this.checkPermission([consts.LOGS_LIST_VIEW]) ||
        this.checkPermission([consts.TOKEN_LIST_VIEW])
    },
  },
  methods: {
    displayText,
    ...mapActions('general', ['setCurrentBranch', 'setCurrentOrg', 'fetchBranches']),
    async init() {
      if (!this.currentBranch) {
        if (this.myBranches.length) {
          this.selectedBranch = this.myBranches[0].id ?? null;
          this.switchBranch();
        }
      } else {
        this.selectedBranch = this.currentBranch.id;
      }
      if (!this.selectedOrganization) {
        this.selectedOrganization = this.organization.id
      }
    },

    async switchBranch() {
      let _this = this
      _this.disableSelect = true
      await this.setCurrentBranch(this.selectedBranch).then(() => {
        this.fetchBranches();
        this.$nextTick(() => {
          _this.$router.go(0)
        })
      }).finally(() => {
        _this.disableSelect = false
      })
    },
    async switchOrganization() {
      let _this = this;
      _this.disableSelect = true
      await this.setCurrentOrg(this.selectedOrganization).then(() => {
        this.selectedBranch = (this.currentBranch) ? this.currentBranch.id : null
        _this.$router.replace({ name: 'DashboardHome', params: { org_code: this.organization.code } })
      }).finally(() => {
        _this.disableSelect = false
      })
    },
    updateView() {
      // force router-view update with router key
      this.$emit('updateView')
    },
    checkPermission(permissions) {
      const checker = new PermissionChecker;
      return checker.check(permissions);
    },
    checkPermissionAny(permissions) {
      const checker = new PermissionChecker;
      return checker.checkAny(permissions);
    },
    anyPermissions(permissions) {
      let result = false;
      const checker = new PermissionChecker;
      permissions.forEach(permission => {
        if (checker.check([permission])) {
          result = true;
        }
      });
      return result;
    }
  },
}
</script>

<style lang="scss">
.nav-organization-selector {
  margin-right: 20px;
  margin-left: 20px;
}
</style>
